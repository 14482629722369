import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Pill.styles';
import styled from 'styled-components';
import {Chip, Avatar} from '@material-ui/core';


class Pill extends Component {
  /**
   * @return {JSX}
   */
  render() {
      return (
        <Chip 
            className={this.props.className}
            avatar={this.props.avatar ? <Avatar>{this.props.avatar}</Avatar> : false} 
            label={this.props.children} 
            size={this.props.size}
        />
      );
  }
}

Pill.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

const StyledPill = styled(Pill)([styles]);
export default StyledPill;
