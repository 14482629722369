import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Submenu.styles';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

/** */
class Submenu extends Component {
    
    /**
    * @return {JSX}
    */
    render() {
        const {route} = this.props;
        return (
            <div className={this.props.className}>
                {route.subRoutes.map(subroute =>
                    <Link key={subroute.route} className={this.props.location.pathname === route.route+subroute.route ? 'selected' : ''} to={route.route+subroute.route}>{subroute.name}</Link>
                )}
                
            </div>
        );
    }
}

Submenu.propTypes = {
    className: PropTypes.string.isRequired,
    route: PropTypes.object.isRequired,
    // history: PropTypes.array.isRequired,
};

const StyledSubmenu = styled(Submenu)([styles]);
export default withRouter(StyledSubmenu);