import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/App.styles';
import styled from 'styled-components';
import Header from './Header';
import Aside from './Aside';
import Main from './Main';
import Login from './Login';
import {BrowserRouter as Router, withRouter} from 'react-router-dom';
// import Alert from './Alert';
// import Grid from '@material-ui/core';

/** */
class App extends Component {

    constructor() {
        super();
        let user = localStorage.getItem('user'); 
        if (user) {
            user = JSON.parse(user);
            user = user.rol === '1' ? user : null;
        }
        this.state = {
            user: user,
        };
    }

    login = user => {
        this.setState({user});
        localStorage.setItem('user', JSON.stringify(user));
    }

    logout = () => {
        this.setState({user: null});
        localStorage.clear();
        
    }

    /**
     * @return {JSX}
     */
    render() {
        return (
            <div className={this.props.className}>
                {
                    this.state.user ?
                        <React.Fragment>
                            <Header user={{...this.state.user}} logout={this.logout} />
                            <div className='container'>
                                <Router>
                                    <Aside />
                                    <Main />                  
                                </Router>
                            </div>
                        </React.Fragment> :
                        <Login login={this.login} />
                }
                
            </div>
        );
    }
}

App.propTypes = {
    className: PropTypes.string.isRequired,
};

const StyledApp = styled(App)([styles]);
export default StyledApp;