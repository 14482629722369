import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Header.styles';
import styled from 'styled-components';

/** */
class Header extends Component {
    /**
    * @return {JSX}
    */
    render() {
        return (
            <header className={this.props.className}>
                <div className='logo'><img src="/img/logo-blanco.png" alt='Naufragio de letras' /></div>
                <div>
                    <div>Has iniciado sesión como {this.props.user.nombre}. <span onClick={this.props.logout}>Cerrar sesión</span></div>
                    <div>Panel de Administración</div>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    className: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};

const StyledHeader = styled(Header)([styles]);
export default StyledHeader;