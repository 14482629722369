import { primaryColor, color, grayColor } from './vars';

export default `
    display: flex;
    justify-content: flex-start;
    padding: 0px 1rem;

    >a{
        font-family: LatoR;
        text-decoration: none;
        font-size: 1.1rem;
        color: ${color(primaryColor)};
        padding: 0 1.5rem 0.75rem 1.5rem;

        &:hover{
            border-bottom: 2px solid ${color(primaryColor)};
            padding-bottom: calc(0.75rem - 2px);
        }

        &.selected{
            font-family: LatoB;
        }
    }
`;