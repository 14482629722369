import { color, grayColor, whiteColor, darkBlueColor, primaryColor, grayColor2 } from './vars';

export default `
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${color(grayColor, 0.3)};
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .close{
        position: absolute;
        top: 2rem;
        left: 1rem;
        background-color: ${color(whiteColor)};

        &:hover{
            background-color: ${color(whiteColor, 0.7)};
        }
    }

    

    >div{
        position: relative;
        background-color: ${color(whiteColor)};
        border-radius: 6px;
        -webkit-box-shadow: 0px 0px 10px 0px ${color(darkBlueColor, 0.75)};
        -moz-box-shadow: 0px 0px 10px 0px ${color(darkBlueColor, 0.75)};
        box-shadow: 0px 0px 10px 0px ${color(darkBlueColor, 0.75)};
        padding: 2rem;

        .closeIcon{
            position: absolute;
            left: 1rem;
            top: 1rem;
            fill: ${color(grayColor)};
            cursor: pointer;
            transition: transform 0.5s ease, fill 0.5s ease;

            &:hover{
                transform: rotate(90deg);
                fill: ${color(grayColor, 0.7)};
            }
        }

        h1{
            color: ${color(primaryColor)};
            font-size: 1.25rem;
            font-family: LatoR;
            text-align: center;
            padding-bottom: 1rem;
        }
    }
`;
 