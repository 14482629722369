import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Login.styles';
import styled from 'styled-components';
import {TextField, Button} from '@material-ui/core';
import {postRequest} from '../../helpers/requests';
import Alert from './Alert';


class Login extends Component {

    constructor(){
        super();
        this.state = {
            email: '',
            pwd: '',
            error: null,
        };
    }

    updateFormFields = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    sendData = () => {
        postRequest.post('/usuarios/login', {
            email: this.state.email,
            pwd: this.state.pwd,
        })
            .then(res => {
                if(res.status === 200){
                    if(res.data.error) {
                        this.setState({error: res.data.error});
                    } else {
                        this.props.login({
                            ID: res.data.ID,
                            nombre: res.data.strNombre,
                            rol: res.data.idRol,
                        });
                    }
                } else {
                    console.log(res.statusText);
                }
            })
            .catch(err => console.log(err));
    }
    

    /**
     * @return {JSX}
     */
    render() {
      return (
          <div className={this.props.className}>
              <div>

                  <p>Introduce tus credenciales</p>
                  {
                      this.state.error &&
                      <div className='error'>
                        <Alert type='error'>{this.state.error.description}</Alert>
                      </div>
                  }
                  <TextField 
                    label='Email'
                    name='email'
                    variant='outlined'
                    fullWidth={true}
                    value={this.state.email}
                    onChange={this.updateFormFields}
                  />
                  <TextField 
                    label='Contraseña'
                    name='pwd'
                    variant='outlined'
                    fullWidth={true}
                    type='password'
                    value={this.state.pwd}
                    onChange={this.updateFormFields}
                  />
                  <Button
                    variant='outlined'
                    onClick={this.sendData}
                  >
                    Acceder
                    </Button>
              </div>
          </div>
      );
  }
}

Login.propTypes = {
  className: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
};

const StyledLogin = styled(Login)([styles]);
export default StyledLogin;
