export default `
    height: 100%;
    width: 100%;

    .container{
        width: 100%;
        height: calc(100vh - 6rem);
        display: flex;
        
    }
`;