import { orangeColor, color, redColor, greenColor, blueColor} from './vars';

export default props => {
    let mainColor = null;
    switch (props.type) {
    case 'warning':
        mainColor = orangeColor;
        break;
    case 'error':
        mainColor = redColor;
        break;
    case 'success':
        mainColor = greenColor;
        break;
    case 'info':
        mainColor = blueColor;
        break;
    }
    const borderColor = color(mainColor);
    const innerColor = color(mainColor, 0.3);

    return (`
        width: 80%;
        margin: 1.5rem auto;
        display: flex;
        padding: 1.25rem;
        border: 4px solid ${borderColor};
        background-color: ${innerColor};
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;


        .icon{
            fill: ${borderColor};
        }

        .text{
            padding-top: 0.5rem;
            padding-left: 1.5rem;
        }

        .close{
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            fill: ${borderColor}
        }

        &.hide{
            opacity: 0;
            // height: 0;
            // padding: 0;
            // border: 0;
            // margin: 0;
        }

        &.minify{
            height: 0;
            padding: 0;
            border: 0;
            margin: 0
        }
    `);
};