import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/PedidosList.styles';
import styled from 'styled-components';
import {postRequest, getRequest} from '../../helpers/requests';
import Table from './Table';
import Alert from './Alert';
import Pill from './Pill';
import Modal from './Modal';
import { blueColor, orangeColor, greenColor, redColor } from '../../styles/vars';
import { Icon } from '@mdi/react';
import { mdiPaypal, mdiCreditCard, mdiPlusCircle, mdiDeleteEmptyOutline, mdiCardTextOutline, mdiArrowLeftDropCircle, mdiArrowRightDropCircle } from '@mdi/js';
import { formatCompleteDate } from '../../helpers/dates';
import { postalCodes } from '../../constants/cp';
import {Button, Tooltip} from '@material-ui/core';
import { Page, Text, View, Document, PDFViewer, StyleSheet} from '@react-pdf/renderer';

class PedidosList extends Component {
    constructor() {
        super();
        this.state = {
            error: null,
            compras: null,
            detail: null,
            startPosition: null,
            openModals: {
                details: false,
                labels: false,
            },
            labelToPrint: null,
        };
    }

    handleOpenModal = modal => {
        this.setState(newState => {
            newState.openModals[modal] = true;
            return newState;
        });
    }

    handleCloseModal = modal => {
        this.setState(newState => {
            newState.openModals[modal] = false;
            newState.startPosition = null;
            return newState;
        });
    }

    viewDetails = index => {
        this.setState({detail: index});
        this.handleOpenModal('details');
    }


    selectPosition = () => {
        const divs = [];
        for (let i = 0; i < 16; i++) {
            divs.push(
                <div onClick={() => {
                    this.setState({startPosition: i});
                }}></div>
            );
        }
        return <div className='positionSelector'>
            <h1>Selecciona la posición de inicio de las etiquetas</h1>
            <div>
                {divs}
            </div>
        </div>
    }

    renderLabels = position => {
        const label = this.state.labelToPrint;
        const pdfStyles = StyleSheet.create({
            page: { paddingTop: '9mm', flexDirection: 'row', flexWrap: 'wrap'},
            label: { width: '105mm', height: '35mm', justifyContent: 'center', alignItems: 'center'},
            labelText: {fontSize: 11, paddingBottom: 2},
            labelTextMini: {fontSize: 9, paddingTop: 2}
        });

        const labels = [];
        for (let i = 0; i < position; i++) {
            labels.push(<View style={pdfStyles.label}></View>);            
        }
        if(label === null) {
            this.state.compras.map(compra => {
                if(compra.seguimiento.intEstado === '0' || compra.seguimiento.intEstado === 0){
                    labels.push(
                        <View style={pdfStyles.label}>
                            <Text style={pdfStyles.labelText}>{compra.direccionEnvio.strNombre}</Text>
                            <Text style={pdfStyles.labelText}>{compra.direccionEnvio.strDireccion}</Text>
                            <Text style={pdfStyles.labelText}>{`${compra.direccionEnvio.intCP}, ${compra.direccionEnvio.strPoblacion}, ${compra.direccionEnvio.strProvincia}`}</Text>
                            <Text style={pdfStyles.labelTextMini}>{`(${postalCodes.find(cp => compra.direccionEnvio.intCP === cp) ? 'Zona A' : 'Zona B'})`}</Text>
                        </View>
                    )
                }
            });
        } else {
            const compra = this.state.compras[label];
            labels.push(
                <View style={pdfStyles.label}>
                    <Text style={pdfStyles.labelText}>{compra.direccionEnvio.strNombre}</Text>
                    <Text style={pdfStyles.labelText}>{compra.direccionEnvio.strDireccion}</Text>
                    <Text style={pdfStyles.labelText}>{`${compra.direccionEnvio.intCP}, ${compra.direccionEnvio.strPoblacion}, ${compra.direccionEnvio.strProvincia}`}</Text>
                    <Text style={pdfStyles.labelTextMini}>{`(${postalCodes.find(cp => compra.direccionEnvio.intCP === cp) ? 'Zona A' : 'Zona B'})`}</Text>
                </View>
            );
        }

        const pdf = <PDFViewer className='pdfViewer'>
            <Document>
                <Page size="A4" style={pdfStyles.page}>
                    {labels}
                </Page>
            </Document>
        </PDFViewer>;

        return pdf;
    }
    changeState = (index, id, estado) => {
        postRequest.post('/compras/seguimiento/update', {
            ID: id,
            intEstado: estado,
        })
            .then(res => {
                console.log(res);
                if(res.status === 200){
                    if(res.data.error) {
                        this.setState({error: res.data.error});
                    } else {
                        this.setState(newState => {
                            newState.compras[index].seguimiento.intEstado = estado;
                            return newState; 
                        })
                    }
                } else {
                    console.log(res.statusText);
                }
            })
            .catch(err => console.log(err));
    }

    componentDidMount(){
        getRequest.get('/compras/complete')
            .then(res => {
                if(res.status === 200){
                    console.log(res);
                    if(res.data.error) {
                        this.setState({error: res.data.error});
                    } else {
                        this.setState({compras: res.data});
                    }
                } else {
                    console.log(res.statusText);
                }
            })
            .catch(err => console.log(err));
    }

    getRows() {
        let i = 0;
        const rows = this.state.compras.map(compra => {
            const index = i;
            i++;
            let stateColor = redColor;
            let state = 'Pendiente de envío';
            let prev = null;
            let next = 'enviado';
            switch (compra.seguimiento.intEstado) {
                case '1':
                case 1:
                    stateColor = orangeColor;
                    state = 'Enviado';
                    prev = 'pendiente de envio';
                    next = 'recibido';
                    break;
                case '2':
                case 2:
                    stateColor = greenColor;
                    state = 'Recibido';
                    prev = 'enviado';
                    next = null;
                    break;
            }
            return [
                {
                    key: 'id',
                    value: compra.ID,
                    align: 'center'
                },
                {
                    key: 'prod',
                    value: compra.carritos.map(producto =>
                        <Pill 
                            avatar={producto.cantidad} 
                            size='small' 
                            bgColor={producto.idTipoProducto === '1' ? blueColor : orangeColor}
                        >
                            {producto.producto}
                        </Pill>),
                },
                {
                    key: 'state',
                    value: <div className='state'>
                        {
                            prev &&
                            <Tooltip title={`Marcar como ${prev}`} placement='left'>
                                <Icon className='changeState' path={mdiArrowLeftDropCircle} size={1} onClick={() => this.changeState(index, compra.idSeguimiento, parseInt(compra.seguimiento.intEstado)-1)}/>
                            </Tooltip>
                        }
                                  
                        <Pill size='small' bgColor={stateColor}>{state}</Pill>
                        {
                            next &&
                            <Tooltip title={`Marcar como ${next}`} placement='right'>
                                <Icon className='changeState' path={mdiArrowRightDropCircle} size={1} onClick={() => this.changeState(index, compra.idSeguimiento, parseInt(compra.seguimiento.intEstado)+1)} />
                            </Tooltip>
                        }
                    </div>,
                    align: 'center'
                },
                {
                    key: 'pago',
                    value: <Tooltip title={compra.idPago === '1' ? 'TPV' : 'Paypal' } placement='left'>
                        <Icon path={compra.idPago === '1' ? mdiCreditCard : mdiPaypal } size={1}/>
                    </Tooltip>,
                    align: 'center'
                },
                {
                    key: 'envio',
                    value: compra.seguimiento.idTipoEnvio === '1' ? 'Ordinario' : 'Certificado',
                    align: 'center'
                },
                {
                    key: 'date',
                    value: formatCompleteDate(compra.seguimiento.dtePedido),
                    align: 'center'
                },
                {
                    key: 'actions',
                    value: <React.Fragment>
                        <Tooltip title='Detalles' placement='top'>
                            <Icon className='icon' path={mdiPlusCircle} size={1} onClick={() => this.viewDetails(index)} />
                        </Tooltip>
                        <Tooltip title='Generar etiquetas' placement='top'>
                            <Icon className='icon' path={mdiCardTextOutline} size={1} onClick={() => {
                                this.setState({labelToPrint: index});
                                this.handleOpenModal('labels');
                            }} />
                        </Tooltip>
                    </React.Fragment>,
                    align: 'center'
                },
            ];
        });
        return rows;
    }
  /**
   * @return {JSX}
   */
  render() {
      const detailed = this.state.detail !== null ? this.state.compras[this.state.detail] : null;
      let stateColor = redColor;
      let state = 'Pendiente de envío';
      let prev = null;
      let next = 'enviado';
      if (detailed) {
        switch (detailed.seguimiento.intEstado) {
            case '1':
            case 1:
                stateColor = orangeColor;
                state = 'Enviado';
                prev = 'pendiente de envio';
                next = 'recibido';
                break;
            case '2':
            case 2:
                stateColor = greenColor;
                state = 'Recibido';
                prev = 'enviado';
                next = null;
                break;
        }
      }
      const columns = [
        {
            label: 'ID',
            key: 'id',
            align: 'center'
        },
        {
            label: 'Productos',
            key: 'prod'
        },
        {
            label: 'Estado',
            key: 'state',
            align: 'center'
        },
        {
            label: 'Pago',
            key: 'pago',
            align: 'center'
        },
        {
            label: 'Envío',
            key: 'envio',
            align: 'center'
        },
        {
            label: 'Fecha de pedido',
            key: 'date',
            align: 'center'
        },
        {
            label: 'Acciones',
            key: 'actions'
        }
      ];

      return (
          <div className={this.props.className}>
              <div className='actions'>
                <Button onClick={() => this.handleOpenModal('labels')} variant="contained">Crear hoja de etiquetas</Button>
              </div>
              {
                  this.state.openModals.labels &&
                  <Modal handleClose={() => this.handleCloseModal('labels')}>
                        {
                            this.state.startPosition === null ?
                                this.selectPosition() :
                                this.renderLabels(this.state.startPosition)
                        }
                  </Modal>
              }
              {
                this.state.openModals.details && 
                <Modal handleClose={() => this.handleCloseModal('details')}>
                    <h1>Detalles del pedido #{detailed.ID}</h1>
                    <div className='details'>
                        <div>
                            <h2>Información general</h2>
                            <p><strong>Productos solicitados:</strong></p>
                            {detailed.carritos.map(producto =>
                                <p><Pill 
                                    avatar={producto.cantidad} 
                                    size='small' 
                                    bgColor={producto.idTipoProducto === '1' ? blueColor : orangeColor}
                                >
                                    {producto.producto}
                                </Pill></p>)}
                            <p><strong>Método de pago:</strong></p>
                            <p>{detailed.idPago === '1' ? 'TPV' : 'Paypal' }</p>
                            <p><strong>Método de envío:</strong></p>
                            <p>{detailed.seguimiento.idTipoEnvio === '1' ? 'Ordinario' : 'Certificado' }</p>
                            <p><strong>Estado del envío:</strong></p>
                            <p><Pill size='small' bgColor={stateColor}>{state}</Pill></p>
                        </div>
                        <div>
                            <h2>Datos de facturación</h2>
                            <p><strong>Nombre:</strong></p>
                            <p>{detailed.direccionFacturacion.strNombre}</p>
                            {
                                detailed.direccionFacturacion.strEmpresa &&
                                <React.Fragment>
                                    <p><strong>Empresa:</strong></p>
                                    <p>{detailed.direccionFacturacion.strEmpresa}</p>
                                </React.Fragment>
                            }
                            <p><strong>DNI:</strong></p>
                            <p>{detailed.direccionFacturacion.strDNI}</p>
                            <p><strong>Dirección:</strong></p>
                            <p>{detailed.direccionFacturacion.strDireccion}</p>
                            <p>{`${detailed.direccionFacturacion.intCP}, ${detailed.direccionFacturacion.strPoblacion}, ${detailed.direccionFacturacion.strProvincia}`}</p>
                        </div>
                        <div>
                            <h2>Datos de envío</h2>
                            <p><strong>Nombre:</strong></p>
                            <p>{detailed.direccionEnvio.strNombre}</p>
                            <p><strong>Dirección:</strong></p>
                            <p>{detailed.direccionEnvio.strDireccion}</p>
                            <p>{`${detailed.direccionEnvio.intCP}, ${detailed.direccionEnvio.strPoblacion}, ${detailed.direccionEnvio.strProvincia}`}</p>
                            <p><strong>Zona:</strong></p>
                            <p>{postalCodes.find(cp => detailed.direccionEnvio.intCP === cp) ? 'Zona A' : 'Zona B'}</p>
                            {
                                detailed.seguimiento.strTel &&
                                <React.Fragment>
                                    <p><strong>Teléfono de contaco:</strong></p>
                                    <p>{detailed.seguimiento.strTel}</p>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    
                </Modal>
              }
              {
                      this.state.error &&
                      <div className='error'>
                        <Alert type='error'>{this.state.error.description}</Alert>
                      </div>
                  }
                {
                    this.state.compras &&
                    <Table
                        cols={columns}
                        rows={this.getRows()}
                    />
                }
              
          </div>
      );
  }
}

PedidosList.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledPedidosList = styled(PedidosList)([styles]);
export default StyledPedidosList;
