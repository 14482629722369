import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Modal.styles';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';

class Modal extends Component {
  /**
   * @return {JSX}
   */
  render() {
      return (
          <div className={this.props.className}>
            <div>
              <Icon className='closeIcon' path={mdiCloseCircle} size={1}  onClick={this.props.handleClose}/>
              {this.props.children}
            </div>
          </div>
      );
  }
}

Modal.propTypes = {
  className: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};

const StyledModal = styled(Modal)([styles]);
export default StyledModal;
