import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Aside.styles';
import styled from 'styled-components';
// import {Hidden, Drawer, List, ListItem, ListItemText} from '@material-ui/core';
// import {InboxIcon, MailIcon} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import routes from '../../constants/routes';

/** */
class Aside extends Component {
    /**
    * @return {JSX}
    */
    render() {
 
        return (
            <aside className={this.props.className}>
                {routes.map(route => 
                    <Link key={route.route} to={route.route}>{route.name}</Link>
                )}
            </aside>
        );
    }
}

Aside.propTypes = {
    className: PropTypes.string.isRequired,
};

const StyledAside = styled(Aside)([styles]);
export default StyledAside;