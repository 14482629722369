import axios from 'axios';

export const postRequest = axios.create({
    baseURL: 'https://api.naufragiodeletras.com',
    // baseURL: 'http://localhost/api-naufragio/public',
    timeout: 10000,
    headers: { 
        'Key': 'alNPd6XpQ6sCLMbXH5KPRjJ7NTzdR1',
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  export const getRequest = axios.create({
    baseURL: 'https://api.naufragiodeletras.com',
    // baseURL: 'http://localhost/api-naufragio/public',
    timeout: 10000,
    headers: { 
        'Key': 'alNPd6XpQ6sCLMbXH5KPRjJ7NTzdR1',
        'Accept': 'application/x-www-form-urlencoded',
    },
  });