import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Empty.styles';
import styled from 'styled-components';
import Alert from './Alert';

/** */
class Empty extends Component {
    /**
    * @return {JSX}
    */
    render() {
        return (
            <div className={this.props.className}>
                <Alert type='info'>Oops! Parece que esta página aún no está creada. Paciencia, los duendes de la programación se están encargando de ello.</Alert>
                <img src='/img/oops.gif' alt='Oops!'/>
            </div>
        );
    }
}

Empty.propTypes = {
    className: PropTypes.string.isRequired,
};

const StyledEmpty = styled(Empty)([styles]);
export default StyledEmpty;