import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Main.styles';
import styled from 'styled-components';
import {Switch, Route} from 'react-router-dom';
import Empty from './Empty';
import Submenu from './Submenu';
import PedidosList from './PedidosList';
import routes from '../../constants/routes';
import {Button} from '@material-ui/core';

/** */
class Main extends Component {

    constructor() {
        super();
        this.state = {
            hide: false,
        }
    }

    hide = () => {
        console.log('hidding!');
        this.setState({hide: !this.state.hide})
    }
    /**
    * @return {JSX}
    */
    render() {
        const hide = this.state.hide ? 'hide' : '';
        return (
            <div className={this.props.className}>
                <div className='header-main'>
                    <div>
                        <Switch>
                            {routes.map(route => 
                                <Route key={route.route} path={route.route} render={() => route.name}/>
                            )}
                            <Route render={() => 'Oops!'} />
                        </Switch>
                    </div>
                    <div>
                        <Switch>
                            {routes.map(route => 
                                <Route key={route.route} path={route.route} render={() => <Submenu route={route}/>}/>
                            )}
                        </Switch>                        
                    </div>
                    
                </div>
                <div className={`body ${hide}`}>
                
                    <Switch>
                        <Route path='/ventas/' exact render={() => <PedidosList />} />
                        <Route render={() => <Empty />} />
                    </Switch>
                    {/* <Fab >
                        <Icon path={mdiExpandAll} />
                    </Fab> */}
                    <Button className='expand' onClick={this.hide} variant="contained">
                        {this.state.hide ? 'Reducir' : 'Expandir'}
                    </Button>
                </div>
                
            </div>
        );
    }
}

Main.propTypes = {
    className: PropTypes.string.isRequired,
    history: PropTypes.array,
};

const StyledMain = styled(Main)([styles]);
export default StyledMain;