import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Table.styles';
import styled from 'styled-components';
import {Table as MuiTable, TableBody, TableHead, TableCell, TableRow, TablePagination} from '@material-ui/core';
import { color } from '../../styles/vars';
import Alert from './Alert';

class Table extends Component {

  constructor(){
    super();
    this.state = {
      rowsPerPage: 10,
      page: 0,
    }
  }

  printHeader = () => {
    const header = this.props.cols.map( col =>
      <TableCell align={col.align ? col.align : 'left'} key={col.key}>
        {col.label}
      </TableCell>);
    return (
      <TableHead>
        <TableRow>
          {header}
        </TableRow>
      </TableHead>
    );

  }

  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
  }

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value, page: 0});
  }

  printRows = () => {
    const {page, rowsPerPage} = this.state;
    const rows = this.props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => 
      <TableRow>
        {row.map(cell => <TableCell align={cell.align ? cell.align : 'left'} key={cell.key}>{cell.value}</TableCell>)}
      </TableRow>
      );
    return <TableBody>
      {rows}
    </TableBody>
  }
  /**
   * @return {JSX}
   */
  render() {
      return (
          <div className={this.props.className}>
            {
              this.props.rows ? 
                <React.Fragment>
                  <MuiTable size='small'>
                    {this.printHeader()}
                    {this.printRows()}
                  </MuiTable>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 50, 100]}
                    component="div"
                    count={this.props.rows.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </React.Fragment> :
                <Alert type='warning'>No hay datos para mostrar</Alert>

            }
            
          </div>
      );
  }
}

Table.propTypes = {
  className: PropTypes.string.isRequired,
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

const StyledTable = styled(Table)([styles]);
export default StyledTable;
