import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/Alert.styles';
import styled from 'styled-components';
import {Icon} from '@mdi/react';
import {mdiAlertCircle, mdiCloseCircle, mdiCheckCircle, mdiInformation, mdiWindowClose} from '@mdi/js';

/** */
class Alert extends Component {
    constructor() {
        super();
        this.state = {
            closed: false,
            minified: false,
        };
    }
    handleClose = () => {
        console.log('close');
        this.setState({closed: true});
        setTimeout(() => this.setState({minified: true}), 200);
    }
    /**
    * @return {JSX}
    */
    render() {
        let icon = null;
        switch (this.props.type) {
        case 'warning':
            icon = mdiAlertCircle;
            break;
        case 'error':
            icon = mdiCloseCircle;
            break;
        case 'success':
            icon = mdiCheckCircle;
            break;
        case 'info':
            icon = mdiInformation;
            break;
        }
        const closed = this.state.closed ? 'hide' : '';
        const minified = this.state.minified ? 'minify' : '';
        return (
            <div className={`${this.props.className} ${closed} ${minified}`}>
                <div><Icon path={icon} size={1.5} className='icon'/></div>
                <div className='text'>{this.props.children}</div>
                <Icon className='close' path={mdiWindowClose} size={1} onClick={this.handleClose}/>
            </div>
        );
    }
}

Alert.propTypes = {
    className: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

const StyledAlert = styled(Alert)([styles]);
export default StyledAlert;