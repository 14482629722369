import { primaryColor, whiteColor, color } from './vars';

export default `
    background-color: ${color(primaryColor)};
    height: 6rem;
    color: ${color(whiteColor)};
    display: flex;
    justify-items: center;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 2rem;
    font-family: 'LatoL';
    >div:last-child{
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 0.9rem;

        >div:last-child{
            font-size: 1.25rem;
            text-transform: uppercase;
        }

        span{
            cursor: pointer;
            color: ${color(whiteColor, 0.7)};
            font-family: LatoB;
            transition: color 0.5s ease;

            &:hover{
                color: ${color(whiteColor)};
            }
        }
        
    }
    .logo{
        height: 100%;
        img{
            height: 100%;
        }
    }

    &.hide{
        display: none;
    }

`;