import { color, whiteColor } from './vars';

export default props => {
    const {bgColor} = props;
    return `&&{
        margin: 0.25rem;
        background-color: ${color(bgColor, 0.2)};
        >div{
            background-color: ${color(bgColor, 0.5)};
            color: ${color(whiteColor)};
            width: 24px;
            height: 24px;
            font-size: 0.75rem;
        }
    }`;
};
 