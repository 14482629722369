import { color, darkBlueColor, whiteColor, grayColor2 } from './vars';

export default `

    thead{
        background-color: ${color(darkBlueColor, 0.7)};
        th{
            color: ${color(whiteColor)};

        }
    }

    tr{
        transition: background-color 0.3s ease;

        &:hover{
            background-color: ${color(grayColor2, 0.2)};
        }
    }


`;
 