export const primaryColor = [0, 64, 74];
export const whiteColor = [250, 251, 253];
export const orangeColor = [234, 132, 10];
export const redColor = [155, 15, 15];
export const greenColor = [34, 123, 32];
export const blueColor = [11, 121, 155];
export const grayColor = [100, 113, 115];
export const grayColor2 = [201, 203, 203];
export const darkBlueColor = [0, 26, 31];

export const color = (color, opacity=1) =>  `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;