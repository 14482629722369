import { color, primaryColor, whiteColor, grayColor2, darkBlueColor} from './vars';

export default `
    width: 100%;
    height: 100vh;
    background: ${color(primaryColor)};
    background: radial-gradient(circle, rgba(81,105,108,1) 0%, ${color(primaryColor)} 50%, ${color(darkBlueColor)} 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // >div:first-child{
    //     width: 80%;
    //     margin: auto;
    // }

    >div{
        width: 40%;
        margin: auto;
        background-color: ${color(grayColor2)};
        padding: 1.5rem 4rem;
        border-radius: 6px;
        color: ${color(primaryColor)};
        display: flex;
        flex-direction: column;
        align-items: center;
        -webkit-box-shadow: 0px 0px 20px 0px ${color(darkBlueColor, 0.75)};
        -moz-box-shadow: 0px 0px 20px 0px ${color(darkBlueColor, 0.75)};
        box-shadow: 0px 0px 20px 0px ${color(darkBlueColor, 0.75)};

        p{
            text-transform: uppercase;
            text-align: center;
            padding: 1rem 0;
        }

        >div{
            padding: 0.5rem 0 0.75rem 0;
        }

        .MuiOutlinedInput-input{
            border: red;
        }

    }
`;
 