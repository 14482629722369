import { color, blueColor, grayColor, grayColor2, primaryColor, whiteColor } from './vars';

export default `
    display: flex;
    flex-direction: column;

    .details{
        display: flex;
        flex-wrap: wrap;
        >div{
            padding: 1rem;
        }
        h2{
            font-size: 1rem;
            color: ${color(blueColor)};
        }

        strong{
            font-size: 0.9rem;
            font-family: LatoB;
            color: ${color(grayColor)};
            margin-top: 1rem;
            margin-bottom: 0.25rem;
            display: inline-block;
        }
    }

    .actions{
        display: flex;
        justify-content: flex-end;
        margin-top: -1rem;
        margin-bottom: 1rem;

        button{
            background-color: ${color(primaryColor, 0.7)};
            color: ${color(whiteColor)};

            &:hover{
                background-color: ${color(primaryColor, 0.8)};
            }
        }
    }

    .icon{
        margin: 0 0.25rem;
        cursor: pointer;
        fill: ${color(primaryColor)};
        transition: fill 0.3s ease;

        &:hover{
            fill: ${color(primaryColor, 0.7)};
        }
    }

    .pdfViewer{
        width: 80vw;
        height: 80vh;
        margin: 1rem;
    }

    .state{
        display: flex;
        align-items: center;
        justify-content: center;
        .changeState{
            cursor: pointer;
            fill: ${color(grayColor2)};
            transition: fill 0.3s ease;
    
            &:hover{
                fill: ${color(grayColor2, 0.7)};
            } 
        }
    }

    

    .positionSelector{
        width: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        >div{
            margin: 0;
            margin-top: 1rem;
            -webkit-box-shadow: 0px 0px 5px 0px ${color(grayColor, 0.75)};
            -moz-box-shadow: 0px 0px 5px 0px ${color(grayColor, 0.75)};
            box-shadow: 0px 0px 5px 0px ${color(grayColor, 0.75)};
            width: 354px;
            height: 500px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;

            >div{
                width: 50%;
                height: 11.8%;
                border: 1px solid ${color(grayColor2, 0.5)};
                transition: background-color 0.5s ease;
                cursor: pointer;

                &:hover{
                    background-color: ${color(primaryColor, 0.2)};

                    & ~ div{
                        background-color: ${color(primaryColor, 0.05)};
                    }
                }
            }
        }
    }
`;
 