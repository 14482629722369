import { color, grayColor2, whiteColor } from './vars';

export default `
    
    width: calc(100% - 150px);

    .header-main{
        background-color: ${color(grayColor2, 0.5)};
        width: 100%;        
        text-align: center;
        padding-top: 2rem;
       
        >div{
            &:first-child{
                font-family: LatoL;
                font-size: 1.5rem;
                padding-bottom: 1rem;
            }
        }
    }

    .body{
        height: calc(100% - 5.35rem);
        padding: 2rem;
        overflow: auto;

        &.hide{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: ${color(whiteColor)};
        }

        
    }

    .expand{
        position: fixed;
        left: 1rem;
        bottom: 2rem;
        font-size: 0.9em;
    }

    
`;
