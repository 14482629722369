import { color, grayColor, whiteColor } from './vars';

export default `
    height: 100%;
    width: 150px;
    background-color: ${color(grayColor, 0.8)};
    padding-top: 1rem;

    >a{
        display: block;
        text-decoration: none;
        color: ${color(whiteColor)};
        font-size: 1.25rem;
        padding: 0.5rem 1rem;

        &:hover{
            background-color: ${color(grayColor)};
        }
    }


`;