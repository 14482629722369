export default [
    {
        key: 'slider',
        name: 'Slider',
        route: '/slider',
        subRoutes: [
            {
                route: '/',
                name: 'Información general',
            },
            {
                route: '/add',
                name: 'Añadir',
            }
        ],

    },
    {
        key: 'ventas',
        name: 'Ventas',
        route: '/ventas',
        subRoutes: [
            {
                route: '/',
                name: 'Histórico',
            },
            {
                route: '/add',
                name: 'Añadir',
            }
        ],

    },
];